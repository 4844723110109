import {
  Item,
  Validate,
  ProductTitle,
  ValidateModernQuote,
  ValidateClassicQuote,
} from "./types";

const isNumber: (arg0: any) => boolean = (value) => {
  return typeof value == "number";
};

export const validateParameters: Validate = (config, container) => {
  if (config === null || config === undefined) {
    console.error("Config is missing");
    return false;
  }

  if (!config.Client || !config.Client.id || !config.Client.name) {
    console.error("Client is not set properly. Make sure id and name are set");
    return false;
  }

  if (!config.onAuthorize) {
    console.error("onAuthorize is not presented or it's not a function.");
    return false;
  }

  if (
    !(
      config.onAuthorize &&
      config.onAuthorize.constructor &&
      config.onAuthorize.call &&
      config.onAuthorize.apply
    )
  ) {
    console.error("onAuthorize is not presented or it's not a function.");
    return false;
  }

  if (!config.payment) {
    console.error("payment is not presented or it's not a function.");
    return false;
  }

  if (
    !(
      config.payment &&
      config.payment.constructor &&
      config.payment.call &&
      config.payment.apply
    )
  ) {
    console.error("payment is not presented or it's not a function.");
    return false;
  }

  if (!container) {
    console.error("container is missing");
    return false;
  }

  if (typeof container !== "string") {
    console.error("container is not valid");
    return false;
  }

  if (
    typeof container === "string" &&
    !(container.startsWith("#") || container.startsWith("."))
  ) {
    console.error("container is not valid");
    return false;
  }

  return true;
};

export const validateModernQuote: ValidateModernQuote = (
  fullBasketValue: number,
  items: Array<Item>
) => {
  if (!isNumber(fullBasketValue)) {
    console.error("Full basket value is not valid.");
    return false;
  }

  if (items) {
    let totalItemAmount = 0;

    for (const item of items) {
      if (!validateItem(item)) {
        console.error("Items are invalid.");
        return false;
      }

      totalItemAmount += item.amount;
    }

    if (totalItemAmount > fullBasketValue) {
      console.error("Currency items sum amount exceeds total currency amount.");
      return false;
    }
  }

  return true;
};

export const validateClassicQuote: ValidateClassicQuote = (
  fullBasketValue: number,
  pointsValue: string,
  productTitle: ProductTitle,
  items: Array<Item>
) => {
  if (!isNumber(fullBasketValue)) {
    console.error("Full basket value is not valid.");
    return false;
  }

  if (isNaN(parseInt(pointsValue, 10))) {
    console.error("Points value is not valid.");
    return false;
  }

  if (productTitle) {
    if (!validateProductTitle(productTitle)) {
      console.error(
        "Product title is invalid. Should have a title and an optional subtitle."
      );
      return false;
    }
  }

  if (items) {
    let totalItemAmount = 0;

    for (const item of items) {
      if (!validateItem(item)) {
        console.error("Items are invalid.");
        return false;
      }
      totalItemAmount += item.amount;
    }

    if (totalItemAmount > fullBasketValue) {
      console.error("Currency items sum amount exceeds total currency amount.");
      return false;
    }
  }

  return true;
};

export const isPositiveInteger = (
  numOfItems: string | number
): boolean => {
  numOfItems = numOfItems.toString().trim();
  numOfItems = numOfItems.replace(/^0+/, "") || "0";
  var n = Math.floor(Number(numOfItems));
  return n !== Infinity && String(n) === numOfItems && n > 0;
};

export const formatStringToInteger = (
  numberOfItems: string | number
): number => {
  numberOfItems = numberOfItems.toString().trim();
  numberOfItems = numberOfItems.replace(/^0+/, "");
  return Number(numberOfItems);
};

const validateItem: (item: Item) => boolean = (item) =>
  (item.category || item.sku) && item.amount && isNumber(item.amount);

const validateProductTitle: (productTitle: ProductTitle) => boolean = (
  productTitle
) => !!productTitle.title;
