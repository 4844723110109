import {
  Actions,
  Button as ButtonShape,
  Item,
  QuoteStatement,
  ProductTitle,
  CreateActionResponse,
  NestedQuoteStatement,
  Config,
  MerchantDetail,
} from "./types";

import {
  validateParameters,
  validateModernQuote,
  validateClassicQuote,
  isPositiveInteger,
  formatStringToInteger,
} from "./Validate";

const actions: Actions = {
  createQuote: (fullBasketValue, items) => {
    if (validateModernQuote(fullBasketValue, items)) {
      return {
        fullBasketValue,
        items,
        widgetType: "slider",
      };
    }

    return null;
  },

  createFixedQuote: (fullBasketValue, items) => {
    if (validateModernQuote(fullBasketValue, items)) {
      return {
        fullBasketValue,
        items,
        widgetType: "fixed",
      };
    }

    return null;
  },

  createClassicQuote: (fullBasketValue, pointsValue, productTitle, items) => {
    if (
      validateClassicQuote(
        fullBasketValue,
        pointsValue.toString(),
        productTitle,
        items
      )
    ) {
      return {
        fullBasketValue,
        pointsValue,
        productTitle,
        items,
        widgetType: "classic",
      };
    }

    return null;
  },
};

const getContainerElement: (container: string) => Element = (container) => {
  if (container.startsWith("#")) {
    return document.getElementById(container.substring(1));
  }

  if (
    container.startsWith(".") &&
    document.getElementsByClassName(container.substring(1)).length === 1
  ) {
    return document.getElementsByClassName(container.substring(1))[0];
  }

  return null;
};

let windowQantasButtonMessageHandler: (event: MessageEvent) => void;

export const messageHandler =
  (config: Config, dataResponse: MerchantDetail) => (e: MessageEvent) => {
    if (e.origin === process.env.REDEMPTION_WIDGET_ORIGIN) {
      const message: {
        messageType: "loaded" | "authorised" | "clicked" | "error" | "closed";
        body: QuoteStatement | NestedQuoteStatement | null;
      } = e.data;
      const numberOfItemsInCart =
        config?.numberOfItemsInCart &&
        formatStringToInteger(config.numberOfItemsInCart);
      switch (message.messageType) {
        case "loaded":
          // the config object is provided by merchant and it calls and returns output of actions.createQuote
          var quote = config.payment(actions) as CreateActionResponse;
          if (quote) {
            const data: {
              fullBasketValue: number;
              productTitle: Array<ProductTitle>;
              items: Array<Item>;
              merchantName: string;
              minTransactionBurnAmt: number;
              mfaOn: boolean;
              mfaAmountThreshold: number;
              transactionPrompt: string;
              conversionRate: number;
              widgetType: "slider" | "fixed" | "classic";
              burnAmount: number | null;
              pointsValue: number | null;
              source: string;
              numberOfItemsInCart?: number;
            } = {
              fullBasketValue: quote.fullBasketValue,
              productTitle: quote.productTitle,
              items: quote.items,
              merchantName: dataResponse.name,
              minTransactionBurnAmt: dataResponse.minTransactionBurnAmt,
              mfaOn: dataResponse.mfaOn,
              mfaAmountThreshold: dataResponse.mfaAmountThreshold,
              transactionPrompt: dataResponse.transactionPrompt,
              conversionRate: dataResponse.burnExchangeRate,
              widgetType: quote.widgetType,
              burnAmount:
                quote.widgetType === "fixed" || quote.widgetType === "classic"
                  ? quote.burnPoints
                  : null,
              pointsValue:
                quote.widgetType === "classic" ? quote.pointsValue : null,
              source: "sho-redemptions-button",
              numberOfItemsInCart:
                quote.widgetType === "slider" ? numberOfItemsInCart : null,
            };

            if (process.env.REDEMPTION_WIDGET_ORIGIN) {
              (e.source as Window).postMessage(
                data,
                process.env.REDEMPTION_WIDGET_ORIGIN
              );
            }

            if (config.onLoad) {
              config.onLoad();
            }
          }
          break;
        case "authorised":
          const quoteStatement =
            (message.body as NestedQuoteStatement).quoteStatement ||
            (message.body as QuoteStatement);
          if (quoteStatement) {
            (e.source as Window).close();
            config.onAuthorize(quoteStatement);
          }

          break;
        case "error":
          const errorMessage = message.body;

          if (!config.onError) {
            console.error("onError is not presented or it's not a function.");
          } else {
            config.onError(errorMessage);
          }

          (e.source as Window).close();
          break;
        case "clicked":
          if (config.onClicked) {
            config.onClicked();
          }

          break;
        case "closed":
          if (config.onClosed) {
            config.onClosed();
          }

          break;
        default:
          break;
      }
    }
  };

const Button: ButtonShape = {
  render: (config, container) => {
    if (!validateParameters(config, container)) {
      return null;
    }

    if (!process.env.API_URL) {
      console.error("API_URL is not defined");
      return null;
    }

    return fetch(
      `${process.env.API_URL}/${config.Client.name}/${config.Client.id}`
    )
      .then((response) => {
        if (response.ok) {
          return response;
        }

        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
        if (
          response.status === 401 ||
          response.status === 500 ||
          response.status === 403
        ) {
          console.error("unAuthorised");
          return null;
        }

        console.error(response.statusText);

        return Promise.reject(response);
      })
      .then((response) => response.json())
      .then((response: MerchantDetail) => {
        const element = getContainerElement(container);

        if (!element) {
          console.error("container could not be found");
          return null;
        }

        if (windowQantasButtonMessageHandler) {
          window.removeEventListener(
            "message",
            windowQantasButtonMessageHandler,
            false
          );
        }

        if (config?.numberOfItemsInCart && !isPositiveInteger(config?.numberOfItemsInCart)) {
          console.error(`numberOfItemsInCart is an invalid number`);
          delete config.numberOfItemsInCart;
        }

        windowQantasButtonMessageHandler = messageHandler(config, response);

        window.addEventListener(
          "message",
          windowQantasButtonMessageHandler,
          false
        );

        const iFrame: HTMLIFrameElement = document.createElement("iframe");

        if (process.env.REDEMPTION_WIDGET) {
          iFrame.src = `${process.env.REDEMPTION_WIDGET}/index.html#/button`;
        }

        element.appendChild(iFrame);

        return null;
      })
      .catch((error: { response: { status: number } }) => {
        console.error(error);
      });
  },
};

export default Button;
